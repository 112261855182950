export default {
  city_name: "Dourados",
  state: "Mato Grosso do Sul",
  country: "Brazil",
  lat: -22.222471,
  lng: -54.8242976,
  zoom: 13,
  currency: "R$",
  properties: [
    {
      id: 0,
      class: "high-end",
      features: { bd: 4, ba: 4, sqft: 3875 },
      coord: { lat: -22.2171546, lng: -54.8383568 },
      address: "Rua Alfredo Richard Klein, 406",
      price: 690000,
      image: "https://cdn.uso.com.br/10374/2018/10/83214438.jpg",
    },
    {
      id: 1,
      class: "high-end",
      features: { bd: 3, ba: 2, sqft: 3875 },
      coord: { lat: -22.2131306, lng: -54.8381051 },
      address: "Rua Mustafá Saleh Abdo Sater, 235",
      price: 720000,
      image: "https://cdn.uso.com.br/10374/2019/09/102143355.jpg",
    },
    {
      id: 2,
      class: "high-end",
      features: { bd: 3, ba: 1, sqft: 4305 },
      coord: { lat: -22.2177877, lng: -54.8269193 },
      address: "Rua Pedro Celestino, 1815",
      price: 720000,
      image:
        "https://lh3.googleusercontent.com/proxy/wrrvgcOvJ5ElSrOLxozCk6HlkrnLpVDRG4OI5jTe_PefD7Xm_AY3W6fYfDSweBl2J0A2jtFIGcetDNg6YL_4lwFQlEDgcW6WgR2nZrwhQNKfbZaQ4uioGeegPVyD_yAHhaV5NYmgcGYFHcI",
    },
    {
      id: 3,
      class: "medium",
      features: { bd: 2, ba: 2, sqft: 1313 },
      coord: { lat: -22.2095253, lng: -54.835627 },
      address: "Rua Eduardo Casaro, 04",
      price: 300000,
      image: "https://cdn.uso.com.br/10374/2018/08/76864524.jpg",
    },
    {
      id: 4,
      class: "medium",
      features: { bd: 3, ba: 2, sqft: 2368 },
      coord: { lat: -22.2505849, lng: -54.7889421 },
      address: "Rua Luiz Egídio de Cerqueira, 2270",
      price: 310000,
      image:
        "https://www.imobiliariacolmeia.com.br/media/galeria_imoveis/5183/5183/tmp/1200x900-5bad390b15843408fe30ae5343b20cc41b06b08d97d31_whatsapp-image-2018-09-27-at-15.jpg",
    },
    {
      id: 5,
      class: "medium",
      features: { bd: 3, ba: 2, sqft: 1679 },
      coord: { lat: -22.219175, lng: -54.8501577 },
      address: "Rua Sadamu Minohara, 215",
      price: 320000,
      image:
        "https://www.imobiliariacolmeia.com.br/media/galeria_imoveis/5596/5596/tmp/1200x900-5e6f78e354aa09068bb8a1bcdf9c893ae8c0f3d098c22_whatsapp-image-2020-03-12-at-14.jpg",
    },
    {
      id: 6,
      class: "medium",
      features: { bd: 2, ba: 1, sqft: 1991 },
      coord: { lat: -22.2084206, lng: -54.8478353 },
      address: "Rua Amael Pompeu Filho, 1625",
      price: 380000,
      image:
        "https://www.imobiliariacolmeia.com.br/media/galeria_imoveis/5189/5189/tmp/1200x2133-5bb3b330605eadf96592136b785064efe43b45c48426a_whatsapp-image-2018-10-02-at-13.jpg",
    },
    {
      id: 7,
      class: "low-end",
      features: { bd: 1, ba: 1, sqft: 1076 },
      coord: { lat: -22.2661543, lng: -54.7730324 },
      address: "Rua Florianópolis, 1090",
      price: 95000,
      image:
        "https://mapio.net/images-immo-detalhe/754183/casa-com-4-quartos-para-alugar-por-r-3300mes-rua-iguassu-1529-vila-sao-luiz-dourados-ms-img-0.jpeg",
    },
    {
      id: 8,
      class: "low-end",
      features: { bd: 2, ba: 1, sqft: 516 },
      coord: { lat: -22.2070525, lng: -54.84204 },
      address: "Rua Arthur Frantz, 1275",
      price: 120000,
      image:
        "https://imgbr.imovelwebcdn.com/avisos/2/29/40/96/11/16/1200x1200/1422772527.jpg",
    },
    {
      id: 9,
      class: "low-end",
      features: { bd: 3, ba: 1, sqft: 3229 },
      coord: { lat: -22.2420301, lng: -54.7460393 },
      address: "Rua Seis, 2540",
      price: 60000,
      image: "https://www.habitacao.engepar.com/media/images_site/banner.png",
    },
  ],
};
